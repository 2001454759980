import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as bootstrap from 'react-bootstrap';

import ReactImageFallback from "react-image-fallback";

// date formate
import Moment from 'moment';

import "./Blog.css";

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Cookies from 'universal-cookie';
// import ReactImageFallback from "react-image-fallback";
class Blog extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            blogNews: [],
            blogPromotions: [],
            blogRinkings: [],
            blogReviews: [],
            blogFeatureds: [],
            specialDeals: [],
            number: []
        };
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'blog';

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        blogNews: result.blogNew,
                        blogPromotions: result.blogPromotion,
                        blogRinkings: result.blogRinking,
                        blogReviews: result.blogReview,
                        blogFeatureds: result.blogFeatured,
                        specialDeals: result.specialDeal,
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }
    
    render() {
        const cookies = new Cookies();

        const {error, blogNews , blogPromotions , blogRinkings , blogReviews , blogFeatureds , specialDeals } = this.state;
        
        const bannerSlide = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const specialDeal = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                }
            ]
        };

        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return (
                <div>
                    <Header />

                    {/* <bootstrap.Container style={{paddingTop: "50px" ,paddingBottom: "20px"}}>
                        <h1>Blog {this.props.blogid}</h1>
                        <p>Siam inter comics</p>
                        <bootstrap.Col md={12} className="card_blogF">
                            <Slider {...regular} className="blogF_box">
                                {blogFeatureds.map(blogFeatured => (
                                    <bootstrap.Col key={blogFeatured.id} style={{marginBottom:"5px" , marginTop:"5px"}}>
                                        <a href="">
                                            <ReactImageFallback
                                                src={`${cookies.get('baseUrlImage')}${blogFeatured.images}`}
                                                fallbackImage={`http://www.siaminterbook.com/${blogFeatured.images}`}
                                                initialImage="loader.gif"
                                                alt={blogFeatured.post_title}
                                                className="blogF_image" />
                                        </a>
                                        <p className="post_blogname" style={{marginTop:"5px"}}>
                                            <font style={{fontSize:"17px"}}>{blogFeatured.post_title}</font><br/>
                                            <font className="post_date">{Moment(blogFeatured.post_date).format('D MMMM Y')} , {blogFeatured.read_view} View</font>
                                        </p>
                                    </bootstrap.Col>
                                ))}
                            </Slider>
                        </bootstrap.Col>
                    </bootstrap.Container> */}

                    <bootstrap.Container style={{paddingTop: "50px" ,paddingBottom: "20px"}}>
                        <bootstrap.Row>
                            <bootstrap.Col md={12}>
                                <Slider {...bannerSlide} >
                                    {blogFeatureds.map(blogFeatured => (
                                        <div key={blogFeatured.id}>
                                            <a href={cookies.get('baseUrl')+`blog/${blogFeatured.id}`}>
                                                <div style={{position: "relative"}}>
                                                    <ReactImageFallback
                                                        src={`${cookies.get('baseUrlImage')}${blogFeatured.images}`}
                                                        fallbackImage={`http://www.siaminterbook.com/${blogFeatured.images}`}
                                                        initialImage={require('../image/giphy.gif')}
                                                        alt={blogFeatured.name}
                                                        className="slideHead" />
                                                        
                                                    <div className="block-title hidden-xs" style={{textAlign:"center"}}>
                                                        <h3 className="title_slide">{blogFeatured.post_title}</h3>
                                                        <small style={{marginTop:"5px"}}><i>{Moment(blogFeatured.post_date).format('D MMMM Y')}</i></small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </Slider>
                            </bootstrap.Col>
                        </bootstrap.Row>
                    </bootstrap.Container>

                    <bootstrap.Container style={{paddingBottom: "20px"}}>
                        <bootstrap.Row>
                            <bootstrap.Col xs={12} md={12} lg={12} style={{marginTop:"50px" , marginBottom:"20px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>New</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={cookies.get('baseUrl')+`blog_viewmore/1`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <bootstrap.Col md={12} className="card">
                                    <bootstrap.Row>
                                        {blogNews.map(blogNew => { 
                                            return  <bootstrap.Col md={4} key={blogNew.id}>
                                                        <a href={cookies.get('baseUrl')+`blog/${blogNew.id}`}>
                                                            <ReactImageFallback
                                                                src={`${cookies.get('baseUrlImage')}${blogNew.images}`}
                                                                fallbackImage={`http://www.siaminterbook.com/${blogNew.images}`}
                                                                initialImage="loader.gif"
                                                                alt={blogNew.post_title}
                                                                className="blog_image" />
                                                        </a>
                                                        <p className="post_blogname" style={{marginTop:"5px"}}>
                                                            <font style={{fontSize:"17px"}}>{blogNew.post_title}</font><br/>
                                                            <font className="post_date">{Moment(blogNew.post_date).format('D MMMM Y')} , {blogNew.read_view} View</font>
                                                        </p>
                                                    </bootstrap.Col>
                                        })}
                                    </bootstrap.Row>
                                </bootstrap.Col>
                            </bootstrap.Col>

                            <bootstrap.Col xs={12} md={6} lg={4} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>Promotion</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={cookies.get('baseUrl')+`blog_viewmore/10`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <bootstrap.Col md={12} className="card">
                                    {blogPromotions.map((blogPromotion,num) => { 
                                        if(num === 0){ 
                                            return <div key={blogPromotion.id}>
                                                        <a href={cookies.get('baseUrl')+`blog/${blogPromotion.id}`}>
                                                            <ReactImageFallback
                                                                src={`${cookies.get('baseUrlImage')}${blogPromotion.images}`}
                                                                fallbackImage={`http://www.siaminterbook.com/${blogPromotion.images}`}
                                                                initialImage="loader.gif"
                                                                alt={blogPromotion.post_title}
                                                                className="blog_image" />
                                                        </a>
                                                        <p className="post_blogname" style={{marginTop:"5px"}}>
                                                            <font style={{fontSize:"17px"}}>{blogPromotion.post_title}</font><br/>
                                                            <font className="post_date">{Moment(blogPromotion.post_date).format('D MMMM Y')} , {blogPromotion.read_view} View</font>
                                                        </p>
                                                    </div>
                                        }else{
                                            return <div key={blogPromotion.id}>
                                                        <bootstrap.Row style={{marginTop:"10.25px",marginBottom:"10.25px"}}>
                                                            <bootstrap.Col md={7} className="">
                                                                <a href={cookies.get('baseUrl')+`blog/${blogPromotion.id}`} >
                                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${blogPromotion.images}`} fallbackImage={`http://www.siaminterbook.com/${blogPromotion.images}`} alt={blogPromotion.post_title} className="blog_image2" width="100%" />
                                                                </a>
                                                            </bootstrap.Col>
                                                            <bootstrap.Col md={5} className="content_blog">
                                                                <p className="font_blogname" style={{marginTop:"5px"}}>
                                                                    <font className="font_blog" style={{marginBottom:"5px"}}> - {blogPromotion.post_title}</font><br/>
                                                                    <font className="font_blogDate" >{Moment(blogPromotion.post_date).format('D MMMM Y')}</font>
                                                                </p>
                                                            </bootstrap.Col>
                                                        </bootstrap.Row>
                                                    </div>
                                        }
                                    })}
                                </bootstrap.Col>
                            </bootstrap.Col>

                            <bootstrap.Col xs={12} md={6} lg={4} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>ตารางวางจำหน่าย</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={cookies.get('baseUrl')+`blog_viewmore/6`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <bootstrap.Col md={12} className="card">
                                    {blogRinkings.map((blogRinking,num) => { 
                                        if(num === 0){ 
                                            return <div key={blogRinking.id}>
                                                        <a href={cookies.get('baseUrl')+`blog/${blogRinking.id}`}>
                                                            <ReactImageFallback
                                                                src={`${cookies.get('baseUrlImage')}${blogRinking.images}`}
                                                                fallbackImage={`http://www.siaminterbook.com/${blogRinking.images}`}
                                                                initialImage="loader.gif"
                                                                alt={blogRinking.post_title}
                                                                className="blog_image" />
                                                        </a>
                                                        <p className="post_blogname" style={{marginTop:"5px"}}>
                                                            <font style={{fontSize:"17px"}}>{blogRinking.post_title}</font><br/>
                                                            <font className="post_date">{Moment(blogRinking.post_date).format('D MMMM Y')} , {blogRinking.read_view} View</font>
                                                        </p>
                                                    </div>
                                        }else{
                                            return <div key={blogRinking.id}>
                                                        <bootstrap.Row style={{marginTop:"10.25px",marginBottom:"10.25px"}}>
                                                            <bootstrap.Col md={7} className="">
                                                                <a href={cookies.get('baseUrl')+`blog/${blogRinking.id}`}>
                                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${blogRinking.images}`} fallbackImage={`http://www.siaminterbook.com/${blogRinking.images}`} alt={blogRinking.post_title} className="blog_image2" width="100%" />
                                                                </a>
                                                            </bootstrap.Col>
                                                            <bootstrap.Col md={5} className="content_blog">
                                                                <p className="font_blogname" style={{marginTop:"5px"}}>
                                                                    <font className="font_blog" style={{marginBottom:"5px"}}>{blogRinking.post_title}</font><br/>
                                                                    <font className="font_blogDate" >{Moment(blogRinking.post_date).format('D MMMM Y')}</font>
                                                                </p>
                                                            </bootstrap.Col>
                                                        </bootstrap.Row>
                                                    </div>
                                        }
                                    })}
                                </bootstrap.Col>
                            </bootstrap.Col>

                            <bootstrap.Col xs={12} md={6} lg={4} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>Review</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={cookies.get('baseUrl')+`blog_viewmore/4`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <bootstrap.Col md={12} className="card">
                                    {blogReviews.map((blogReview,num) => { 
                                        if(num === 0){  
                                            return <div key={blogReview.id}>
                                                        <a href={cookies.get('baseUrl')+`blog/${blogReview.id}`}>
                                                            <ReactImageFallback
                                                                src={`${cookies.get('baseUrlImage')}${blogReview.images}`}
                                                                fallbackImage={`http://www.siaminterbook.com/${blogReview.images}`}
                                                                initialImage="loader.gif"
                                                                alt={blogReview.post_title}
                                                                className="blog_image" />
                                                        </a>
                                                        <p className="post_blogname" style={{marginTop:"5px"}}>
                                                            <font style={{fontSize:"17px"}}>{blogReview.post_title}</font><br/>
                                                            <font className="post_date">{Moment(blogReview.post_date).format('D MMMM Y')} , {blogReview.read_view} View</font>
                                                        </p>
                                                    </div>
                                        }else{
                                            return <div key={blogReview.id}>
                                                        <bootstrap.Row style={{marginTop:"10.25px",marginBottom:"10.25px"}}>
                                                            <bootstrap.Col md={7} className="">
                                                                <a href={cookies.get('baseUrl')+`blog/${blogReview.id}`} >
                                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${blogReview.images}`} fallbackImage={`http://www.siaminterbook.com/${blogReview.images}`} alt={blogReview.post_title} className="blog_image2" width="100%" />
                                                                </a>
                                                            </bootstrap.Col>
                                                            <bootstrap.Col md={5} className="content_blog">
                                                                <p className="font_blogname" style={{marginTop:"5px"}}>
                                                                    <font className="font_blog" style={{marginBottom:"5px"}}>{blogReview.post_title}</font><br/>
                                                                    <font className="font_blogDate" >{Moment(blogReview.post_date).format('D MMMM Y')}</font>
                                                                </p>
                                                            </bootstrap.Col>
                                                        </bootstrap.Row>
                                                    </div>
                                        }
                                    })}
                                </bootstrap.Col>
                            </bootstrap.Col>

                            <bootstrap.Col xs={12} md={12} lg={12} style={{marginTop:"50px" , marginBottom:"20px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>Featured</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={cookies.get('baseUrl')+`blog_viewmore/1`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <bootstrap.Col md={12} className="card">
                                    <bootstrap.Row>
                                        {blogFeatureds.map(blogFeatured => { 
                                            return  <bootstrap.Col md={4} key={blogFeatured.id}>
                                                        <a href={cookies.get('baseUrl')+`blog/${blogFeatured.id}`}>
                                                            <ReactImageFallback
                                                                src={`${cookies.get('baseUrlImage')}${blogFeatured.images}`}
                                                                fallbackImage={`http://www.siaminterbook.com/${blogFeatured.images}`}
                                                                initialImage="loader.gif"
                                                                alt={blogFeatured.post_title}
                                                                className="blog_image" />
                                                        </a>
                                                        <p className="post_blogname" style={{marginTop:"5px"}}>
                                                            <font style={{fontSize:"17px"}}>{blogFeatured.post_title}</font><br/>
                                                            <font className="post_date">{Moment(blogFeatured.post_date).format('D MMMM Y')} , {blogFeatured.read_view} View</font>
                                                        </p>
                                                    </bootstrap.Col>
                                        })}
                                    </bootstrap.Row>
                                </bootstrap.Col>
                            </bootstrap.Col>
                        </bootstrap.Row>
                    </bootstrap.Container>

                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px"}}>
                        <bootstrap.Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                            <h3 className=""><font style={{borderBottom: "3px solid red"}}>Special Deal</font></h3><br/> 
                            <Slider {...specialDeal} >
                                {specialDeals.map(specialDeal => (
                                    <div key={specialDeal.id}>
                                        <div style={{margin:"0px 15px"}} class="tophit_margin">
                                            <a href={specialDeal.linked}>
                                                <ReactImageFallback src={`http://localhost/siamintercomics/${specialDeal.picture}`} fallbackImage={`https://www.smmpublishing.com/images/special/${specialDeal.picture}`} alt={specialDeal.topic} className="img_specialDeal" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </bootstrap.Container>
                    </div>

                    <Footer />
                </div>
            );
        }
    }
}

export default Blog;