import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.css";
import {
  BrowserRouter as 
  // Router,
  Switch,
  Route,
  // Link,
  // useRouteMatch,
  useParams
} from "react-router-dom";

import './App.css';

import Home from '../Home/Home';
import Book from '../Book/Book';
import Sevenday from '../Sevenday/Sevenday';
import SevendayChapter from '../Sevenday/SevendayChapter';
import Blog from '../Blog/Blog';
import BlogBlog from '../Blog/BlogBlog'; 
import BlogViewmore from '../Blog/BlogViewmore'; 
import ReadingIndex from '../Reading/Reading_index';
import Reading from '../Reading/Reading';
import Category from '../Category/Category';

import Cookies from 'universal-cookie';

function HomePage() {
  const cookies = new Cookies();
  cookies.set('myPage', 'home', { path: '/' });
  return <Home />
}
function BookPage() {
  var { bookid } = useParams();
  const cookies = new Cookies();
  cookies.set('myPage', 'book', { path: '/' });
  return <Book bookid={bookid}/>
}
function SevendayPage() {
  var { bookid } = useParams();
  const cookies = new Cookies();
  cookies.set('myPage', 'book', { path: '/' });
  return <Sevenday bookid={bookid}/>
}
function SevendayChapterPage() {
  var { bookid } = useParams();
  var { chapterid } = useParams();
  const cookies = new Cookies();
  cookies.set('myPage', 'book', { path: '/' });
  return <SevendayChapter bookid={bookid} chapterid={chapterid} />
}
function BlogPage() {
  const cookies = new Cookies();
  cookies.set('myPage', 'blog', { path: '/' });
  return <Blog/>;
}
function BlogPage2() {
  var { blogid } = useParams();
  const cookies = new Cookies();
  cookies.set('myPage', 'blog', { path: '/' });
  return <BlogBlog blogid={blogid}/>;
}
function BlogViewmorePage() {
  var { categoryBlog_id } = useParams();
  const cookies = new Cookies();
  cookies.set('myPage', 'blog', { path: '/' });
  return <BlogViewmore categoryBlog_id={categoryBlog_id}/>;
}
function ReadingPage() {
  const cookies = new Cookies();
  cookies.set('myPage', 'reading', { path: '/' });
  return <ReadingIndex/>;
}
function ReadingPage2() {
  var { readingid } = useParams();
  const cookies = new Cookies();
  cookies.set('myPage', 'reading', { path: '/' });
  return <Reading readingid={readingid}/>;
}
function CategoryPage() {
  const cookies = new Cookies();
  cookies.set('myPage', 'category', { path: '/' });
  return <Category />
}

class App extends Component {
  render() { 
    const cookies = new Cookies();
    cookies.set('baseUrl', 'https://siamintercomics.com/', { path: '/' });
    cookies.set('baseUrlImage', 'https://www.siaminterbook.com/', { path: '/' });
    cookies.set('baseUrlApi', 'https://backend.siamintercomics.com/', { path: '/' });
    return (
      <div>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/book/:bookid">
            <BookPage />
          </Route>
          <Route path="/sevenday/:bookid">
            <SevendayPage />
          </Route>
          <Route path="/sevenday_chapter/:bookid/:chapterid">
            <SevendayChapterPage />
          </Route>
          <Route path="/category">
            <CategoryPage />
          </Route>
          <Route path="/blogs">
            <BlogPage />
          </Route>
          <Route path="/blog/:blogid">
            <BlogPage2 />
          </Route>
          <Route path="/blog_viewmore/:categoryBlog_id">
            <BlogViewmorePage />
          </Route>
          <Route path="/readings">
            <ReadingPage />
          </Route>
          <Route path="/reading/:readingid">
            <ReadingPage2 />
          </Route>
        </Switch>
      </div>
    );
  }
}
export default App