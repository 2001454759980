import React from 'react';
import {Container} from 'react-bootstrap';
import * as Icon from "react-icons/fa";

import './Footer.css';

class Footer extends React.Component{
    render(){
        return(
            <div id="footer">
                <Container id="footer">
                    <div className="row text-center text-xs-center text-sm-left text-md-left hidden-xs" style={{marginTop:"20px",paddingTop:"30px"}}>
                        <div className="col-xs-2 col-sm-1 col-md-1 footer_border_left2">
                        </div>
                        <div className="col-xs-10 col-sm-3 col-md-3" >
                            <h4 style={{textAlign:"left"}}><b>Sport</b></h4>
                            <ul className="list-unstyled quick-links footer_ul1 footer_font" >
                                <li><a href="https://www.smmsport.com/"><i className="fa fa-angle-double-right"></i>Smm Sport</a></li>
                                <li><a href="https://www.smmsport.com/tv/"><i className="fa fa-angle-double-right"></i>Smm Tv</a></li>
                                <li><a href="https://www.smmsport.com/radio/"><i className="fa fa-angle-double-right"></i>Smm Sprot Radio</a></li>
                            </ul>
                        </div>
                        <div className="hidden-xs col-xs-12 col-sm-1 col-md-1 footer_border_left">
                        </div>
                        <div className="col-xs-10 col-sm-3 col-md-3" >
                            <h4 style={{textAlign:"left"}}><b>Publication</b></h4>
                            <ul className="list-unstyled quick-links footer_ul2 footer_font">
                                <li><a href="https://siamintercomics.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-angle-double-right"></i>Siam Inter Comics</a></li>
                                <li><a href="https://siamintershop.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-angle-double-right"></i>Siam Inter Shop</a></li>
                                <li><a href="https://www.smmpublishing.com" target="_blank" rel="noopener noreferrer"><i className="fa fa-angle-double-right"></i>SMM Publishing</a></li>
                            </ul>
                        </div>
                        <div className="hidden-xs col-xs-12 col-sm-1 col-md-1 footer_border_left">
                        </div>
                        <div className="col-xs-10 col-sm-3 col-md-3">
                            <h4 style={{textAlign:"left"}}><b>Social</b></h4>
                            <ul className="list-unstyled quick-links footer_ul3 footer_font">
                                <li><Icon.FaGooglePlusSquare />: smmmarketing7@gmail.com</li>
                                <li><a href="https://www.facebook.com/siaminterbooks/" target="_blank" rel="noopener noreferrer"><Icon.FaFacebookSquare /> : @siaminterbooks</a></li>
                                <li><a href="https://www.instagram.com/siaminterbooks/?hl=en" target="_blank" rel="noopener noreferrer"><Icon.FaInstagram /> : @siaminterbooks</a></li>
                                <li><a href="https://twitter.com/siaminterbook?lang=en" target="_blank" rel="noopener noreferrer"><Icon.FaTwitterSquare />  : @SiamInterBook</a></li>
                                <li><Icon.FaPhoneSquare />  : 02-693-0904</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:"20px"}}>
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                            <ul className="list-unstyled list-inline social text-center">
                                <li className="list-inline-item"><a href="https://www.facebook.com/siaminterbooks/" target="_blank" rel="noopener noreferrer"><Icon.FaFacebookSquare style={{fontSize:"30px"}}/></a></li>
                                <li className="list-inline-item"><a href="https://www.instagram.com/siaminterbooks/?hl=en" target="_blank" rel="noopener noreferrer"><Icon.FaInstagram style={{fontSize:"30px"}}/></a></li>
                                <li className="list-inline-item"><a href="https://twitter.com/siaminterbook?lang=en" target="_blank" rel="noopener noreferrer"><Icon.FaTwitterSquare style={{fontSize:"30px"}}/></a></li>
                            </ul>
                        </div>
                        <hr/>
                    </div>	
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                            <p>© บริษัท เอสเอ็มเอ็ม พลัส จำกัด. 459 ซอยลาดพร้าว 48 ถนนลาดพร้าว แขวงสามเสนนอก เขตห้วยขวาง กทม. 10310 </p>
                            {/* <p>&copy SIAM INTER MULTIMEDIA PUBLIC COMPANY LIMITED, All Rights Reserved.</p> */}
                        </div>
                        <hr/>
                    </div>	
                </Container>
            </div>
        );
    }
}
 
export default Footer;