import React from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import * as Icon from "react-icons/io";
// import {Container, Row, Col, Form, Button} from 'react-bootstrap';

import './Header.css';
// import logo from './sic-logo-icon.png';
import Cookies from 'universal-cookie';

class Header extends React.Component{
    render(){
        const cookies = new Cookies();
        // console.log(cookies.get('myPage')); // Pacman
        return(
            <div>
                <Navbar collapseOnSelect expand="lg" bg="custom" variant="custom">
                    <Navbar.Brand href="#home" className="logo-icon"><img src={require('../image/sic-logo-icon.png')} alt="Siamintercomics Logo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight:"10px"}}>
                        <Icon.IoIosMenu />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            
                        </Nav>
                        <Nav style={{/*marginRight: "25px"*/}}>
                            <Nav.Link href="/" className={cookies.get('myPage') === 'home' && 'active'}>Home</Nav.Link>
                            <Nav.Link href="/category" className={cookies.get('myPage') === 'category' && 'active'}>Category</Nav.Link>
                            <Nav.Link href="/readings" className={cookies.get('myPage') === 'reading' && 'active'}>Reading</Nav.Link>
                            <Nav.Link href="/blogs" className={cookies.get('myPage') === 'blog' && 'active'}>Blog</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}
 
export default Header;