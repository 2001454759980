import React from 'react';
// import DocumentMeta from 'react-document-meta';
import MetaTags from 'react-meta-tags';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Home.css";

import {Container, Col, Row} from 'react-bootstrap';

import Header from '../Header/Header' 
import Footer from '../Footer/Footer'

// date formate
import Moment from 'moment';

import ReactImageFallback from "react-image-fallback";

import Cookies from 'universal-cookie';
// import {Container, Row, Col, Form, Button} from 'react-bootstrap';

class Home extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            banners: [],
            blogPromotions: [],
            blogNews: [],
            readings: [],
            sevenDays: [],
            bookSellers: [],
            bookNewers: [],
            bookInterests: [],
            specialDeals: []
        };
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'home/banner';

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        banners: result.banner,
                        blogPromotions: result.blogPromotion,
                        blogNews: result.blogNew,
                        readings: result.reading,
                        sevenDays: result.sevenDay,
                        bookSellers: result.bookSeller,
                        bookNewers: result.bookNewer,
                        bookInterests: result.bookInterest,
                        specialDeals: result.specialDeal
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }
    render(){
        const cookies = new Cookies();

        // const {error, banners, blogRinkings, sevenDays, blogNews} = this.state;
        const {error, banners , blogPromotions  , blogNews , readings , sevenDays , specialDeals , bookSellers , bookNewers , bookInterests } = this.state;
        // Slick Slider
        const bannerSlide = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
                // responsive: [
                //     {
                //         breakpoint: 768,
                //         settings: {
                //             arrows: false,
                //         }
                //     }
                // ]
        };
        // const blogPromotionSlide = {
        //     arrows: false,
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1
        // };
        
        const sevenDay = {
            centerMode: true,
            centerPadding: '165px',
            slidesToShow: 3,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        centerPadding: '50px',
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: '140px',
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '90px',
                        arrows: false,
                    }
                }
            ]
        };

        const regular = {
            dots: true,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                }
            ]
        };

        const specialDeal = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                }
            ]
        };
        // meta tag
        // const meta = {
        //     title: 'Some Meta Title',
        //     description: 'I am a description, and I can create multiple tags',
        //     canonical: 'https://siamintercomics.com/',
        //     meta: {
        //       charset: 'utf-8',
        //       name: {
        //         keywords: 'react,meta,document,html,tags'
        //       }
        //     }
        // };
        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return(
                // <DocumentMeta {...meta}>
                <div>
                    <MetaTags>
                        <title>React aaa</title>
                        <meta name="description" content="Some description." />
                        <meta property="og:title" content="MyApp" />
                        <meta property="og:image" content="path/to/image.jpg" />
                    </MetaTags>
                    <Header />
                    <Container style={{paddingTop: "50px" ,paddingBottom: "20px"}}>
                        <Row>
                            <Col md={12}>
                                <Slider {...bannerSlide} >
                                    {banners.map(banner => (
                                        <div key={banner.id}>
                                            <a className="" href={banner.link}>
                                                <div style={{position: "relative"}}>
                                                    <ReactImageFallback
                                                        src={`${cookies.get('baseUrlImage')}${banner.picture}`}
                                                        fallbackImage={`http://www.siaminterbook.com/${banner.picture}`}
                                                        initialImage={require('../image/giphy.gif')}
                                                        alt={banner.name}
                                                        className="slideHead" />
                                                        
                                                    <div className="block-title hidden-xs" style={{textAlign:"center"}}>
                                                        <h3 className="title_slide">{banner.name}</h3>
                                                        <center><div className="post_content">{banner.detail}</div></center>
                                                        <small style={{marginTop:"5px"}}><i>{Moment(banner.postDate).format('D MMMM Y')}</i></small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col xs={12} md={6} lg={4} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>Promotion</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={`${cookies.get('baseUrl')}blogs`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <Col md={12} className="card">
                                    {blogPromotions.map(blogPromotion => (
                                        <Row key={blogPromotion.id} style={{marginTop:"10px",marginBottom:"10px"}}>
                                            <Col md={7} className="">
                                                <a href={`${cookies.get('baseUrl')}blog/${blogPromotion.id}`}>
                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${blogPromotion.images}`} fallbackImage={`http://www.siaminterbook.com/${blogPromotion.images}`} alt={blogPromotion.post_title} className="image_blog" width="100%" />
                                                </a>
                                            </Col>
                                            <Col md={5} className="content_blog">
                                                <p className="font_blog content_name" style={{marginBottom:"5px"}}>{blogPromotion.post_title}</p>
                                                <p className="font_blogDate" >{Moment(blogPromotion.post_date).format('D MMMM Y')}</p>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Col>
                            <Col xs={12} md={6} lg={4} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>New</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={`${cookies.get('baseUrl')}blogs`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <Col md={12} className="card">
                                    {blogNews.map(blogNew => (
                                        <Row key={blogNew.id} style={{marginTop:"10px",marginBottom:"10px"}}>
                                            <Col md={7} className="">
                                                <a href={`${cookies.get('baseUrl')}blog/${blogNew.id}`}>
                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${blogNew.images}`} fallbackImage={`http://www.siaminterbook.com/${blogNew.images}`} alt={blogNew.post_title} className="image_blog" width="100%" />
                                                </a>
                                            </Col>
                                            <Col md={5} className="content_blog">
                                                <p className="font_blog content_name" style={{marginBottom:"5px"}}>{blogNew.post_title}</p>
                                                <p className="font_blogDate" >{Moment(blogNew.post_date).format('D MMMM Y')}</p>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Col>
                            <Col xs={12} md={6} lg={4} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>Reading</font>
                                    <a className="btn btn-danger" style={{padding: "2px 8px" , float: "right"}} href={`${cookies.get('baseUrl')}readings`}>
                                        <font className="" style={{fontSize:"14px"}}>All</font>
                                    </a>
                                </h3>
                                <Col md={12} className="card">
                                    {readings.map(reading => (
                                        <Row key={reading.id} style={{marginTop:"10px",marginBottom:"10px"}}>
                                            <Col md={7} className="">
                                                <a href={`${cookies.get('baseUrl')}reading/${reading.id}`}>
                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${reading.images}`} fallbackImage={`http://www.siaminterbook.com/${reading.images}`} alt={reading.post_title} className="image_blog" width="100%" />
                                                </a>
                                            </Col>
                                            <Col md={5} className="content_blog">
                                                <p className="font_blog content_name" style={{marginBottom:"5px"}}>{reading.post_title}</p>
                                                <p className="font_blogDate" >{Moment(reading.post_date).format('D MMMM Y')}</p>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Col>
                        </Row>
                    </Container>

                    <Container style={{paddingTop: "50px" ,paddingBottom: "30px"}}>
                        <h3 className=""><font style={{borderBottom: "3px solid red"}}>การ์ตูนเด็ด 7 วัน 7 เรื่อง</font></h3><br/> 
                        <Slider {...sevenDay} >
                            {sevenDays.map(sevenDay => (
                                <div key={sevenDay.id}>
                                    <div className={"col-xs-12 "+sevenDay.day_at} style={{margin: "0px 20px"}}>
                                        <a href={`${cookies.get('baseUrl')}sevenday/${sevenDay.id}`}>
                                            <div className="row" style={{padding: "5px 5px", margin: "10px 0px 10px 0px"}} >
                                                <div className="col-xs-12">
                                                    <ReactImageFallback src={`${cookies.get('baseUrlImage')}${sevenDay.book_image}`} fallbackImage={`http://www.siaminterbook.com/${sevenDay.book_image}`} alt={sevenDay.book_name} className="image7d" width="100%" />
                                                </div>
                                                
                                                <div className="col-sm-12 text-center">
                                                    <p className={"btn_7day btn btn-"+sevenDay.day_at} style={{}}>{sevenDay.day_at}</p>
                                                </div>
                                            </div> 
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Container>

                    <Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                        <h3 className=""><font style={{borderBottom: "3px solid red"}}>ขายดี</font></h3><br/> 
                        <Slider {...regular} >
                            {bookSellers.map(bookSeller => (
                                <div key={bookSeller.id}>
                                    <div style={{margin:"0px 15px"}}>
                                        <a href={`${cookies.get('baseUrl')}book/${bookSeller.id}`}>
                                            <ReactImageFallback src={`${cookies.get('baseUrlImage')}${bookSeller.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${bookSeller.book_pic}`} alt={bookSeller.book_name} className="regular_img" width="100%" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Container>

                    <Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                        <h3 className=""><font style={{borderBottom: "3px solid red"}}>มาใหม่</font></h3><br/> 
                        <Slider {...regular} >
                            {bookNewers.map(bookNewer => (
                                <div key={bookNewer.id}>
                                    <div style={{margin:"0px 15px"}}>
                                        <a href={`${cookies.get('baseUrl')}book/${bookNewer.id}`}>
                                            <ReactImageFallback src={`${cookies.get('baseUrlImage')}${bookNewer.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${bookNewer.book_pic}`} alt={bookNewer.book_name} className="regular_img" width="100%" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Container>

                    <Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                        <h3 className=""><font style={{borderBottom: "3px solid red"}}>แนะนำ</font></h3><br/> 
                        <Slider {...regular} >
                            {bookInterests.map(bookInterest => (
                                <div key={bookInterest.id}>
                                    <div style={{margin:"0px 15px"}}>
                                        <a href={`${cookies.get('baseUrl')}book/${bookInterest.id}`}>
                                            <ReactImageFallback src={`${cookies.get('baseUrlImage')}${bookInterest.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${bookInterest.book_pic}`} alt={bookInterest.book_name} className="regular_img" width="100%" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Container>
                    
                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px"}}>
                        <Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                            <h3 className=""><font style={{borderBottom: "3px solid red"}}>Special Deal</font></h3><br/> 
                            <Slider {...specialDeal} >
                                {specialDeals.map(specialDeal => (
                                    <div key={specialDeal.id}>
                                        <div style={{margin:"0px 15px"}} class="tophit_margin">
                                            <a href={specialDeal.linked}>
                                                <ReactImageFallback src={`${cookies.get('baseUrlImage')}${specialDeal.picture}`} fallbackImage={`https://www.smmpublishing.com/images/special/${specialDeal.picture}`} alt={specialDeal.topic} className="img_specialDeal" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Container>
                    </div>

                    <Footer />
                </div>
                // </DocumentMeta> 
            );
        }
    }
}

export default Home;