import React from 'react';

import * as bootstrap from 'react-bootstrap';

import ReactImageFallback from "react-image-fallback";

// date formate
import Moment from 'moment';

import "./Blog.css";

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Cookies from 'universal-cookie';
// import ReactImageFallback from "react-image-fallback";
class BlogViewmore extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            blogs: [],
        };
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'blog/viewmore/'+this.props.categoryBlog_id;

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        blogs: result.blog,
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }
    
    render() {
        const cookies = new Cookies();

        const {error, blogs} = this.state;
        
        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return (
                <div>
                    <Header />

                    <bootstrap.Container style={{paddingBottom: "20px"}}>
                        <bootstrap.Row>
                            <bootstrap.Col xs={12} md={12} lg={12} style={{marginTop:"30px"}}>
                                <h3 className="" style={{paddingBottom: "10px"}}>
                                    <font>BLOG</font>
                                </h3>
                                {/* <p>Siam inter book</p> */}
                                <bootstrap.Row>
                                    {blogs.map((blog) => { 
                                        return <bootstrap.Col key={blog.id} md={4}>
                                                    <a href={cookies.get('baseUrl')+`blog/${blog.id}`}>
                                                        <ReactImageFallback
                                                            src={`${cookies.get('baseUrlImage')}${blog.images}`}
                                                            fallbackImage={`http://www.siaminterbook.com/${blog.images}`}
                                                            initialImage="loader.gif"
                                                            alt={blog.post_title}
                                                            className="blog_image" />
                                                    </a>
                                                    <p className="post_blogname" style={{marginTop:"5px"}}>
                                                        <font style={{fontSize:"17px"}}>{blog.post_title}</font><br/>
                                                        <font className="post_date">{Moment(blog.post_date).format('D MMMM Y')} , {blog.read_view} View</font>
                                                    </p>
                                                </bootstrap.Col>
                                    })}
                                </bootstrap.Row>
                            </bootstrap.Col>

                        </bootstrap.Row>
                    </bootstrap.Container>

                    <Footer />
                </div>
            );
        }
    }
}

export default BlogViewmore;