import React from 'react';

import './Category.css';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as bootstrap from 'react-bootstrap';

import Header from '../Header/Header';

import ReactImageFallback from "react-image-fallback";

import Cookies from 'universal-cookie';

class Category extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            categorys:[],
            category_id: [],
            banners: [],
            books: [],
        }
        this.selectBook = this.selectBook.bind(this);
        this.selectBook_page = this.selectBook_page.bind(this);
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'category/category';

        this.getCategory(apiUrl);
        this.onFormSubmit(this.state);
    }

    getCategory = apiUrl =>{

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categorys: result.category
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }

    selectBook(e) {
        if(e.target.checked) {
            this.setState({
                data_page: "1",
                category_id: [ ...this.state.category_id, e.target.value],
                // aaaa: e.target.value,
            }, 
            () => {
                this.onFormSubmit(this.state);
            });
        }
        else{
            let remove = this.state.category_id.indexOf(e.target.value);
            this.setState({
                    data_page: "1",
                    category_id: this.state.category_id.filter((_, i) => i !== remove)
                },
                () => {
                    this.onFormSubmit(this.state);
                }
            );
        }
    }
    selectBook_page(e) {
        this.setState({
            data_page: e.target.getAttribute('data_page'),
        }, 
        () => {
            this.onFormSubmit(this.state);
        });
    }

    onFormSubmit(data) {
        const cookies = new Cookies();
        let apiUrl;
          apiUrl = cookies.get('baseUrlApi')+'category/searchBook';
    
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
    
        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          myHeaders
        }
    
        fetch(apiUrl, options)
          .then(res => res.json())
          .then(
            (result) => {
                this.setState({
                    books: result.book,
                    page: result.page,
                    previous_page: result.previous_page,
                    next_page: result.next_page,
                    total_page: result.total_page,
                })     
            },
            (error) => {
              this.setState({ error });
            }
          )
    }
    
    
    render() {
        const cookies = new Cookies();
        
        let table = [];
        var i;
        var page = this.state.page;
        var total_page = this.state.total_page;
        var p_active;

        var next_page = this.state.next_page;
        var previous_page = this.state.previous_page;
        
        table.push(
            <bootstrap.Pagination.Item id={"first_page"} data_page={1} key="first_page" onClick={this.selectBook_page}>First</bootstrap.Pagination.Item>
        )

        if(page === 1){
            table.push(
                <bootstrap.Pagination.Item id={"previous"} key="previous" disabled>Previous</bootstrap.Pagination.Item>
            )
        }else{
            table.push(
                <bootstrap.Pagination.Item id={"previous"} data_page={previous_page} key="previous" onClick={this.selectBook_page}>Previous</bootstrap.Pagination.Item>
            )
        }

        for (i = 1; i < total_page+1; i++) {
            if(i === page){p_active="active";}else{p_active="";} 

            if(page-2>=2 && (i>2 && i<page-2)){
                table.push(
                    <bootstrap.Pagination.Ellipsis key="p_1" />
                )
                i=page-2;
            }

            table.push(
                <bootstrap.Pagination.Item id={"page_"+i} data_page={i} className={p_active} key={i} onClick={this.selectBook_page} >{i}</bootstrap.Pagination.Item>
            )

            if(page+5<=total_page && (i>=page+2 && i<=total_page-2)){
                table.push(
                    <bootstrap.Pagination.Ellipsis key="p_2" />
                )
                i=total_page-2;
            }
        }

        if(page === total_page){
            table.push(
                <bootstrap.Pagination.Item id={"next"} key="Next" disabled>Next</bootstrap.Pagination.Item>
            )
        }else{
            table.push(
                <bootstrap.Pagination.Item id={"next"} data_page={next_page} key="Next" onClick={this.selectBook_page}>Next</bootstrap.Pagination.Item>
            )
        }

        
        table.push(
            <bootstrap.Pagination.Item id={"last_page"} data_page={total_page} key="last_page" onClick={this.selectBook_page}>Last</bootstrap.Pagination.Item>
        )
        
        const {error , books , categorys } = this.state;
 
        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return (
                <div>
                    <Header />
                    {/* <ReactImageFallback
                        src={require('../image/noimage2.jpg')}
                        fallbackImage={require('../image/sic-logo-icon.png')}
                        initialImage="loader.gif"
                        alt="cool image should be here"
                        className="my-image" /> */}

                    <bootstrap.Container style={{paddingTop: "30px" ,paddingBottom: "20px"}}>
                        <bootstrap.Row>
                            <bootstrap.Col sm={12}>
                                <h1>ผลงาน </h1>
                                <p>Siamintercomics</p>
                                <br/>
                                <bootstrap.Row>
                                    <bootstrap.Col className="col_search" md={4} lg={3} style={{paddingRight:"0px"}}>
                                        <h4 style={{borderBottom: "1px solid #ddd" , paddingBottom:"10px"}}>ค้นหาหนังสือ</h4>
                                        <label style={{fontWeight:"bold"}}>หมวดหมู่หนังสือ</label>
                                        {categorys.map(category => (
                                            <bootstrap.Form.Check key={category.id} onClick={this.selectBook} className="searchCB" style={{fontSize:"14px"}}
                                                custom
                                                inline
                                                label={category.category_name}
                                                type="checkbox"
                                                id={`category_id`+category.id}
                                                name="category_id"
                                                value={category.id}
                                            />
                                        ))} 
                                    </bootstrap.Col>

                                    <bootstrap.Col className="col_content" sm={12} md={8} lg={9} >
                                        <h4 style={{borderBottom: "1px solid #ddd" , paddingBottom:"10px"}}>หนังสือทั้งหมด</h4>
                                        
                                        <bootstrap.Row>
                                            {books.map(book => (
                                                <bootstrap.Col key={book.id} xs={6} sm={4} md={4} lg={3} className="img_box">
                                                    <a href={`${cookies.get('baseUrl')}book/${book.id}`}>
                                                        <ReactImageFallback
                                                        src={`http://localhost/smmpublishing/${book.book_pic}`}
                                                        fallbackImage={`https://www.smmpublishing.com/images/products/${book.book_pic}`}
                                                        initialImage="loader.gif"
                                                        alt={book.book_name}
                                                        className="my-image regular_s" />
                                                    </a>
                                                </bootstrap.Col>
                                            ))} 
                                        
                                        </bootstrap.Row>
                                        <br/>
                                        <center>
                                            <bootstrap.Pagination>
                                                {table}
                                            </bootstrap.Pagination>
                                        </center>

                                    </bootstrap.Col>
                                </bootstrap.Row>
                            </bootstrap.Col>
                        </bootstrap.Row>
                    </bootstrap.Container>
                </div>
            );
        }
    }
}

// class Blog extends React.Component{
//     render(){
//         // Slick Slider
//         const settings = {
//             arrows: true,
//             dots: true,
//             infinite: true,
//             speed: 500,
//             slidesToShow: 1,
//             slidesToScroll: 1
//           };
        
//         return(
//             <div>
//                 <Header />
//                 <Container>
//                     <Slider {...settings} >
//                     <div>
//                         <h3>1</h3>
//                     </div>
//                     <div>
//                         <h3>2</h3>
//                     </div>
//                     <div>
//                         <h3>3</h3>
//                     </div>
//                     <div>
//                         <h3>4</h3>
//                     </div>
//                     <div>
//                         <h3>5</h3>
//                     </div>
//                     <div>
//                         <h3>6</h3>
//                     </div>
//                     </Slider>
//                 </Container>
//             </div>  
//         );
//     }
// }

export default Category;