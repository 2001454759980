import React from 'react';

import * as bootstrap from 'react-bootstrap';

import ReactImageFallback from "react-image-fallback";

// date formate
import Moment from 'moment';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Cookies from 'universal-cookie';
// import ReactImageFallback from "react-image-fallback";
class ReadingIndex extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            readings: [],
            Releateds: [],
        };
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'reading/reading/'+this.props.readingid;

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        readings: result.reading,
                        releateds: result.releated,
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }
    
    render() {

        const {error, readings , releateds} = this.state;
        
        var HtmlToReactParser = require('html-to-react').Parser;
        var htmlToReactParser = new HtmlToReactParser();

        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return (
                <div>
                    <Header />

                    <bootstrap.Container style={{paddingTop: "50px" ,paddingBottom: "20px"}}>
                        <bootstrap.Row>
                            {readings.map(reading => { 
                                return <bootstrap.Col md={12} key={reading.id}>
                                            <bootstrap.Row>
                                                <bootstrap.Col xs={12} sm={12} md={10} className="box_reading">
                                                    <h3>{reading.post_title}</h3>
                                                    <p className="post_dateBlog">post : {Moment(reading.post_date).format('D MMMM Y')} , {reading.read_view} view</p>
                                                    <p className="post_dateBlog">บทความ : {reading.name}</p>
                                                    <center style={{marginBottom:"20px"}}>
                                                        <ReactImageFallback
                                                            src={`http://localhost/siamintercomics/${reading.images}`}
                                                            fallbackImage={`http://www.siaminterbook.com/${reading.images}`}
                                                            initialImage="loader.gif"
                                                            alt={reading.post_title}
                                                            className=""
                                                            width="100%" />
                                                    </center>
                                                    {htmlToReactParser.parse(reading.post_content)}
                                                </bootstrap.Col>
                                                <bootstrap.Col xs={12} md={2} className="box_releateds">
                                                    <h3>Releated</h3>
                                                    {releateds.map(releated => { 
                                                        return  <div key={releated.id}>
                                                                    <a href={`http://localhost:3000/reading/${releated.id}`} >
                                                                        <ReactImageFallback
                                                                            src={`http://localhost/siamintercomics/${releated.images}`}
                                                                            initialImage="loader.gif"
                                                                            alt={releated.post_title}
                                                                            className=""
                                                                            width="95%" />
                                                                    </a>
                                                                    <p className="post_blogname" style={{margin:"0px"}}><a href={`http://localhost:3000/blog/${releated.id}`}>{releated.post_title}</a></p>
                                                                    <p className="post_date" style={{marginBottom:"20px"}}>posted on {Moment(releated.post_date).format('D MMMM Y')}</p>
                                                                </div>
                                                    })}
                                                </bootstrap.Col>    
                                            </bootstrap.Row>
                                       </bootstrap.Col>
                            })}
                        </bootstrap.Row>
                    </bootstrap.Container>

                    <Footer />
                </div>
            );
        }
    }
}

export default ReadingIndex;