import React from 'react';

import * as bootstrap from 'react-bootstrap';

import ReactImageFallback from "react-image-fallback";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// date formate
import Moment from 'moment';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Cookies from 'universal-cookie';
// import ReactImageFallback from "react-image-fallback";
class BlogBlog extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            blogs: [],
            blogReleateds: [],
            tophits: [],
            specialDeals: []
        };
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'blog/blog/'+this.props.blogid;

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        blogs: result.blog,
                        blogReleateds: result.blogReleated,
                        tophits: result.tophit,
                        specialDeals: result.specialDeal
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }
    
    render() {

        const {error, blogs , blogReleateds , tophits , specialDeals} = this.state;

        const specialDeal = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                }
            ]
        };

        // const regular = {
        //     dots: true,
        //     infinite: true,
        //     slidesToShow: 4,
        //     slidesToScroll: 4,
        //     responsive: [
        //         {
        //             breakpoint: 991,
        //             settings: {
        //                 slidesToShow: 5,
        //                 slidesToScroll: 5, 
        //             }
        //         },
        //         {
        //             breakpoint: 768,
        //             settings: {
        //                 slidesToShow: 4,
        //                 slidesToScroll: 4,
        //                 arrows: false,
        //             }
        //         },
        //         {
        //             breakpoint: 425,
        //             settings: {
        //                 slidesToShow: 3,
        //                 slidesToScroll: 3,
        //                 arrows: false,
        //             }
        //         }
        //     ]
        // };
        
        var HtmlToReactParser = require('html-to-react').Parser;
        var htmlToReactParser = new HtmlToReactParser();

        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return (
                <div>
                    <Header />

                    <bootstrap.Container style={{padding:"50px 0px"}}>
                        <bootstrap.Row>
                            {blogs.map(blog => { 
                                return <bootstrap.Col md={12} key={blog.id}>
                                            <h2>{blog.category_name}</h2>
                                            <bootstrap.Row>
                                                <bootstrap.Col className="blog_content" xs={12} md={9}>
                                                    <h3>{blog.post_title}</h3>
                                                    <p className="post_dateBlog">post : {Moment(blog.post_date).format('D MMMM Y')} , {blog.read_view} view</p>
                                                    <p className="post_dateBlog">บทความ : {blog.name}</p>
                                                    <center style={{marginBottom:"20px"}}>
                                                        <ReactImageFallback
                                                            src={`http://localhost/siamintercomics/${blog.images}`}
                                                            initialImage="loader.gif"
                                                            alt={blog.post_title}
                                                            className=""
                                                            width="100%" />
                                                    </center>
                                                    {htmlToReactParser.parse(blog.post_content)}
                                                </bootstrap.Col>
                                                <bootstrap.Col className="blog_releated" xs={12} md={3}>
                                                    <h3>Releated</h3>
                                                    {blogReleateds.map(blogReleated => { 
                                                        return  <div key={blogReleated.id}>
                                                                    <a href={`http://localhost:3000/blog/${blogReleated.id}`} >
                                                                        <ReactImageFallback
                                                                            src={`http://localhost/siamintercomics/${blogReleated.images}`}
                                                                            initialImage="loader.gif"
                                                                            alt={blogReleated.post_title}
                                                                            className=""
                                                                            width="95%" />
                                                                    </a>
                                                                    <p className="post_blogname" style={{margin:"0px"}}><a href={`http://localhost:3000/blog/${blogReleated.id}`}>{blogReleated.post_title}</a></p>
                                                                    <p className="post_date" style={{marginBottom:"20px"}}>posted on {Moment(blog.post_date).format('D MMMM Y')}</p>
                                                                </div>
                                                    })}
                                                </bootstrap.Col>    
                                            </bootstrap.Row>
                                       </bootstrap.Col>
                            })}
                        </bootstrap.Row>
                    </bootstrap.Container>

                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px",backgroundColor:"#fbfbfb"}}>
                        <bootstrap.Container>
                            <bootstrap.Row>
                                <bootstrap.Col md={12}>
                                    <h3 style={{textAlign:"center"}}><font style={{borderBottom: "3px solid red"}}>Top Hit</font></h3><br/> 
                                    
                                    <bootstrap.Row>
                                            {tophits.map(tophit => (
                                                <bootstrap.Col key={tophit.id} xs={6} sm={6} md={3} lg={3} className="" style={{width:"100%"}}>
                                                    <a href={`http://localhost:3000/blog/${tophit.id}`}>
                                                        <ReactImageFallback
                                                        src={`http://localhost/siamintercomics/${tophit.images}`}
                                                        fallbackImage={`https://www.smmpublishing.com/images/products/${tophit.images}`}
                                                        initialImage="loader.gif"
                                                        alt={tophit.post_title}
                                                        className="img_tophit" style={{border:"1px solid #ddd"}} width="100%"/>
                                                    </a>
                                                </bootstrap.Col>
                                            ))} 
                                        
                                    </bootstrap.Row>

                                </bootstrap.Col>
                            </bootstrap.Row>
                        </bootstrap.Container>
                    </div>
                    
                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px"}}>
                        <bootstrap.Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                            <h3 className=""><font style={{borderBottom: "3px solid red"}}>Special Deal</font></h3><br/> 
                            <Slider {...specialDeal} >
                                {specialDeals.map(specialDeal => (
                                    <div key={specialDeal.id}>
                                        <div style={{margin:"0px 15px"}} class="tophit_margin">
                                            <a href={specialDeal.linked}>
                                                <ReactImageFallback src={`http://localhost/siamintercomics/${specialDeal.picture}`} fallbackImage={`https://www.smmpublishing.com/images/special/${specialDeal.picture}`} alt={specialDeal.topic} className="img_specialDeal" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </bootstrap.Container>
                    </div>

                    <Footer />
                </div>
            );
        }
    }
}

export default BlogBlog;