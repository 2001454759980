import React from 'react';

import './Book.css';

import * as bootstrap from 'react-bootstrap';

import ReactImageFallback from "react-image-fallback";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// date formate
// import Moment from 'moment';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Cookies from 'universal-cookie';

// import ReactImageFallback from "react-image-fallback";
class Book extends React.Component{
    constructor(props){
        super(props); 
        this.state = {
            error: null,
            books: [],
            book_subs: [],
            row_bookSubs: [],
            tophits: [],
            bookInterests: [],
            specialDeals: [],
            // tophits: [],
        };
    }

    componentDidMount(){
        
        const cookies = new Cookies();

        const apiUrl = cookies.get('baseUrlApi')+'book/book/'+this.props.bookid;

        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        books: result.book,
                        book_subs: result.book_sub,
                        row_bookSubs: result.row_bookSub,
                        tophits: result.tophit,
                        bookInterests: result.bookInterest,
                        specialDeals: result.specialDeal
                        // tophits: result.tophit,
                    })
                },
                (error) => {
                    this.setState({ error })
                }
            )
    }
    
    render() {
        const cookies = new Cookies();

        const {error, books ,book_subs ,tophits ,bookInterests ,specialDeals ,row_bookSubs} = this.state;
        // const {error, blogs , blogReleateds , tophits} = this.state;
        
        var HtmlToReactParser = require('html-to-react').Parser;
        var htmlToReactParser = new HtmlToReactParser();

        const regular = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                }
            ]
        };

        const book_Interests = {
            dots: true,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                }
            ]
        };

        const tophit = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                }
            ]
        };

        const specialDeal = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3, 
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                }
            ]
        };

        if(error){
            return(
                <div>Error: {error.message}</div>
            )
        }else{
            return (
                <div>
                    <Header />

                    <bootstrap.Container style={{}}>
                        <bootstrap.Row>
                            {books.map(book => { 
                                return  <bootstrap.Col md={12} key={book.id}>
                                            <bootstrap.Col className="book_content" xs={12} md={12}>
                                                <h2>{book.book_name}</h2>
                                                <p style={{color:"#666"}}>Siamintercomics</p>
                                            </bootstrap.Col>
                                            <bootstrap.Col className="book_content" xs={12} md={12}>
                                                <bootstrap.Row>
                                                    <bootstrap.Col className="" xs={12} sm={12} md={6}>
                                                        <center>
                                                            <ReactImageFallback
                                                                src={`http://localhost/siamintercomics/${book.book_pic}`}
                                                                fallbackImage={`https://www.smmpublishing.com/images/products/${book.book_pic}`}
                                                                initialImage="loader.gif"
                                                                alt={book.book_name}
                                                                className=""
                                                                style={{width:"90%",maxWidth:"300px",border:"1px solid #ddd"}} />
                                                        </center>
                                                    </bootstrap.Col> 
                                                    <bootstrap.Col className="" xs={12} sm={12} md={6}>
                                                        <p>{book.book_name}</p>
                                                        Writer : {book.at_name}<br/>
                                                        Translator : {book.name}<br/>
                                                        Publish Date : {book.pub_name}<br/>
                                                        Publisher : {book.installed}<br/>
                                                        Page : {book.page_book}<br/>
                                                        ราคา : {book.price}<br/>
                                                        ขนาด : {book.size_book}<br/><br/>
                                                        {row_bookSubs > '3' ? 
                                                        <Slider {...regular} >
                                                            {book_subs.map(book_sub => (
                                                                <div key={book_sub.id}>
                                                                    <div style={{margin:"0px 15px"}}>
                                                                        <a href={`${cookies.get('baseUrl')}book/${book_sub.id}`}>
                                                                            <ReactImageFallback src={`http://localhost/siamintercomics/${book_sub.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${book_sub.book_pic}`} alt={book_sub.book_name} className="regular_img" width="100%" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Slider> : 
                                                        <bootstrap.Row>
                                                            {book_subs.map(book_sub => (
                                                                <bootstrap.Col md={4} key={book_sub.id}>
                                                                    <div>
                                                                        <a href={`${cookies.get('baseUrl')}book/${book_sub.id}`}>
                                                                            <ReactImageFallback src={`http://localhost/siamintercomics/${book_sub.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${book_sub.book_pic}`} alt={book_sub.book_name} className="regular_img" width="100%" />
                                                                        </a>    
                                                                    </div>
                                                                </bootstrap.Col>
                                                            ))}
                                                        </bootstrap.Row> }
                                                        {/* {row_bookSubs > "3"&&
                                                            <Slider {...regular} >
                                                                {book_subs.map(book_sub => (
                                                                    <div key={book_sub.id}>
                                                                        <div style={{margin:"0px 15px"}}>
                                                                            <ReactImageFallback src={`http://localhost/siamintercomics/${book_sub.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${book_sub.book_pic}`} alt={book_sub.book_name} className="regular_img" width="100%" />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                        }
                                                        {row_bookSubs <= "3"&&
                                                            <bootstrap.Row>
                                                                {book_subs.map(book_sub => (
                                                                    <bootstrap.Col md={4} key={book_sub.id}>
                                                                        <div>
                                                                            <ReactImageFallback src={`http://localhost/siamintercomics/${book_sub.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${book_sub.book_pic}`} alt={book_sub.book_name} className="regular_img" width="100%" />
                                                                        </div>
                                                                    </bootstrap.Col>
                                                                ))}
                                                            </bootstrap.Row>
                                                        } */}
                                                    </bootstrap.Col>
                                                </bootstrap.Row>
                                            </bootstrap.Col>
                                            <bootstrap.Col className="book_content" xs={12} md={12} style={{borderTop:"1px solid #ddd",padding:"50px 0px",marginTop:"50px"}}>
                                                <bootstrap.Row>
                                                    <bootstrap.Col className="" xs={12} sm={12} md={8}>
                                                        เนื้อหาโดยสังเขป : <br/><br/>
                                                        {htmlToReactParser.parse(book.b_detail)}
                                                    </bootstrap.Col> 
                                                    <bootstrap.Col className="" xs={12} sm={12} md={4}>
                                                        <a href={book.code}>
                                                            <ReactImageFallback src={require('../image/LOGO-SHOP.jpg')} alt="logo" className="" width="100%" />
                                                        </a>
                                                    </bootstrap.Col>
                                                </bootstrap.Row>
                                            </bootstrap.Col>
                                        </bootstrap.Col>
                            })}
                        </bootstrap.Row>
                    </bootstrap.Container>

                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px",backgroundColor:"#fbfbfb"}}>
                        <bootstrap.Container>
                            <h3 style={{textAlign:"center"}}><font style={{borderBottom: "3px solid red"}}>Top Hit</font></h3><br/> 
                            <Slider {...tophit} >
                                {tophits.map(tophit => (
                                    <div key={tophit.id} >
                                        <div class="tophit_margin">
                                            <a href={`http://localhost:3000/blog/${tophit.id}`}>
                                                <ReactImageFallback
                                                src={`http://localhost/siamintercomics/${tophit.images}`}
                                                fallbackImage={`https://www.smmpublishing.com/images/products/${tophit.images}`}
                                                initialImage="loader.gif"
                                                alt={tophit.post_title}
                                                className="img_tophit" style={{border:"1px solid #ddd"}} width="100%"/>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </bootstrap.Container>
                    </div>

                    
                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px"}}>
                        <bootstrap.Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                            {/* <bootstrap.Row>
                                <bootstrap.Col md={12}> */}
                                    <h3 className=""><font style={{borderBottom: "3px solid red"}}>หนังสือแนะนำ</font></h3><br/> 
                                    <Slider {...book_Interests} >
                                        {bookInterests.map(bookInterest => (
                                            <div key={bookInterest.id}>
                                                <div style={{margin:"0px 15px"}}>
                                                    <a href={`${cookies.get('baseUrl')}book/${bookInterest.id}`}>
                                                        <ReactImageFallback src={`http://localhost/siamintercomics/${bookInterest.book_pic}`} fallbackImage={`https://www.smmpublishing.com/images/products/${bookInterest.book_pic}`} alt={bookInterest.book_name} className="regular_img" width="100%" />
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                {/* </bootstrap.Col>
                            </bootstrap.Row> */}
                        </bootstrap.Container>
                    </div>

                    
                    <div style={{borderTop:"1px solid #ddd",padding:"50px 0px"}}>
                        <bootstrap.Container style={{paddingTop: "30px" ,paddingBottom: "30px"}}>
                            <h3 className=""><font style={{borderBottom: "3px solid red"}}>Special Deal</font></h3><br/> 
                            <Slider {...specialDeal} >
                                {specialDeals.map(specialDeal => (
                                    <div key={specialDeal.id}>
                                        <div style={{margin:"0px 15px"}} class="tophit_margin">
                                            <a href={specialDeal.linked}>
                                                <ReactImageFallback src={`http://localhost/siamintercomics/${specialDeal.picture}`} fallbackImage={`https://www.smmpublishing.com/images/special/${specialDeal.picture}`} alt={specialDeal.topic} className="img_specialDeal" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </bootstrap.Container>
                    </div>


                    {/* <div style={{borderTop:"1px solid #ddd",padding:"50px 0px",backgroundColor:"#fbfbfb"}}>
                        <bootstrap.Container>
                            <bootstrap.Row>
                                <bootstrap.Col md={12}>
                                    <h3 style={{textAlign:"center"}}><font style={{borderBottom: "3px solid red"}}>แนะนำ</font></h3><br/> 
                                    
                                    <bootstrap.Row>
                                            {tophits.map(tophit => (
                                                <bootstrap.Col key={tophit.id} xs={6} sm={6} md={3} lg={3} className="" style={{width:"100%"}}>
                                                    <a href={`http://localhost:3000/blog/${tophit.id}`}>
                                                        <ReactImageFallback
                                                        src={`http://localhost/siamintercomics/${tophit.images}`}
                                                        fallbackImage={`https://www.smmpublishing.com/images/products/${tophit.images}`}
                                                        initialImage="loader.gif"
                                                        alt={tophit.post_title}
                                                        className="" style={{border:"1px solid #ddd"}} width="100%"/>
                                                    </a>
                                                </bootstrap.Col>
                                            ))} 
                                        
                                    </bootstrap.Row>

                                </bootstrap.Col>
                            </bootstrap.Row>
                        </bootstrap.Container>
                    </div> */}

                    <Footer />
                </div>
            );   
        }
    }
}

export default Book;