import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App/App'

import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';

const AppWithRouter = () => (
    <CookiesProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </CookiesProvider>
)

ReactDOM.render(<AppWithRouter />, document.getElementById('root'))